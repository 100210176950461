import React, {useState, Fragment} from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { Row, Col, Tooltip, Modal, Input } from 'antd';

const { TextArea } = Input;

const StyledBody = styled.div`
  width: 96%;
  position: relative;
  font-size: 8pt;
  color: #000;
`;

function DescriptionList({descriptions}) {
    return (
        <Fragment>
            {descriptions &&
            descriptions.map((description, index) => (
                <div key={index} dangerouslySetInnerHTML={{__html: description}}/>
            ))}
        </Fragment>
    );
}

function ProductQRCode({sku}){
    return (
        <QRCode value={`https://phongvu.vn/esl-s${sku}.html?pv_source=esl`} size={47} />
    );
}

function ContentLarge({product, updateProduct}) {
    const [showModal, setShowModal] = useState(false);
    const initialDescriptions = product.descriptions;
    const [updatedDescription, setUpdatedDescription] = useState(initialDescriptions);
    const changeModal = () => {
        setShowModal(!showModal);
    }
    
    const updateProductDescriptions = () => {
        const newDescription = document.getElementById("productDescription").value.trim().split("\n");
        setUpdatedDescription(newDescription);
        updateProduct({...product, descriptions: newDescription});
        changeModal();
    };

    return (
        <div>
            <Tooltip onClick = {() => {changeModal()}} placement="right" title="Click để sửa">
                <StyledBody className="mx-auto">
                    <Row gutter={4}>
                        <Col span={18} className="mt-1" style={{height: '44px', overflow: 'hidden'}}>
                            <DescriptionList 
                                descriptions={updatedDescription} 
                            />
                        </Col>
                        <Col span={6} className="mt-1">
                            <ProductQRCode sku={product.sku}/>
                        </Col>
                    </Row>
                </StyledBody>
            </Tooltip>
            <Modal
                title="Sửa thông tin"
                visible={showModal}
                onCancel={changeModal}
                onOk={updateProductDescriptions}
                maskClosable={false}
            >
                <TextArea id="productDescription" rows={8} defaultValue={updatedDescription.join("\n")}/>
            </Modal>
        </div>
    );
}

export default ContentLarge;
