import React, {useState} from 'react';
import styled from 'styled-components';
import {Tooltip, Modal, Select} from 'antd';
const { Option } = Select;
const StyledBody = styled.div`
    text-align: center;
    position: relative;
    overflow: hidden;
    height: 100%;
`;

const StyledName = styled.div`
    color: white;
    text-transform: uppercase;
`;

const StyledDiscountAmount = styled.div`
    color: #f7f021;
    font-size: 23pt;
    padding-left: 40px;
    padding-right: 40px;
    font-family: Montserrat-Bold;
`;

const StyledDiscountPercent = styled.div`
    color: #f7f021;
    font-size: 26pt;
    font-family: Montserrat-Bold;
`;

const StyledDiscountPrice = styled.div`
    font-size: 10pt;
    width: 50%;
    color: #f7f021;
    float: left;
    text-align: left;
    border-left: 3px solid #f7f021;
    padding-left: 5px;
`; 

const StyledPrice = styled.div`
    color: white;
    font-size: 13pt;
    text-decoration: line-through;
`;

const StyledPriceSmall = styled.div`
    color: white;
    text-decoration: line-through;
    float: left;
    font-size: 10pt;
    width: 48%;
    text-align: right;
    padding-right: 10px;
`;

const StyledFinalPrice = styled.div`
    color: #f7f021;
    font-size: 28pt;
    font-family: Montserrat-Bold;
    line-height: 1.2;
`;

const StyledGift = styled.div`
    position: absolute;
    right: 75px;
    bottom: 58px;
    height: 100px;
    color: #233780;
    text-shadow: -1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff;
`;

const StyledGiftName = styled.div`
    position: absolute;
    right: 90px;
    font-size: 9pt;
    top: 35px;
    width: 120px;
    max-height: 52px;
    overflow: hidden;
`;

const StyledPromoRange = styled.div`
    font-size: 15px;
    color: #fff;
`;

const StyledPromoRangeSmall = styled.div`
    position: absolute;
    top: 151px;
    font-size: 13px;
    color: #233780;
    width: 52%;
    padding-left: 20px;
`;

function Name({product, height, bigFont}) {
    const [updatedProduct, setUpdatedProduct] = useState(product);
    let name = product.name;
    const [updatedName, setUpdatedName] = useState(name);

    const updateProduct = () => {
        let newName = prompt("Sửa tên sản phẩm", product.name);
        if(newName){
            product.name = newName;
            setUpdatedProduct(product);
            setUpdatedName(updatedProduct.name);
        }
    };

    return (
      <StyledName>
        <div 
            style={{fontSize: bigFont ? '11pt' : '10pt', height: height ? height : '65px', overflow: "hidden"}} 
            onClick={ () => updateProduct() }
        >
            <div style={{float: 'left', clear: 'left', height: '20px', width: '100px'}}></div>
            <div style={{float: 'right', clear: 'right', height: '20px', width: '100px'}}></div>
            <div style={{float: 'left', clear: 'left', height: '20px', width: '70px'}}></div>
            <div style={{float: 'right', clear: 'right', height: '20px', width: '70px'}}></div>
            <div style={{float: 'left', clear: 'left', height: '20px', width: '55px'}}></div>
            <div style={{float: 'right', clear: 'right', height: '20px', width: '55px'}}></div>
            <Tooltip placement="top" title="Click để sửa tên sản phẩm">
                {updatedName}
            </Tooltip>
        </div>
      </StyledName>
  );
}

function DiscountAmount({product}){
    let discount_amount = 0;
    if(product.price > 0){
        if(product.price - product.finalPrice > 1000000){
            let discount_percent = (product.price - product.finalPrice) / product.price * 100;
            discount_amount = discount_percent.toFixed(0);
            return (
                <StyledDiscountPercent>
                  Giảm {discount_amount}%
                </StyledDiscountPercent>
            );
        }
        return (
            <StyledDiscountAmount>
            Giảm {(product.price - product.finalPrice).toLocaleString()}Đ
            </StyledDiscountAmount>
        );
    }
}

function DiscountPrice({product}){
    let discount_amount = 0;
    if(product.price > 0){
        if(product.price - product.finalPrice > 1000000){
            let discount_percent = (product.price - product.finalPrice) / product.price * 100;
            discount_amount = discount_percent.toFixed(0);
            return (
                <StyledDiscountPrice>
                  Giảm {discount_amount}%
                </StyledDiscountPrice>
            );
        }
        return (
            <StyledDiscountPrice>
            Giảm {(product.price - product.finalPrice).toLocaleString()}Đ
            </StyledDiscountPrice>
        );
    }
}

function Price({price}){
    return (
        <StyledPrice>
          {price.toLocaleString()}Đ
        </StyledPrice>
    );
}

function PriceSmall({price}){
    return (
        <StyledPriceSmall>
          {price.toLocaleString()}Đ
        </StyledPriceSmall>
    );
}

function FinalPrice({price}){
    return (
        <StyledFinalPrice>
          {price.toLocaleString()}Đ
        </StyledFinalPrice>
    );
}

function GiftSelector({product, update}){
    let showModal = false;
    const [updatedShowModal, setShowModal] = useState(showModal);
    const changeModal = () => {
        setShowModal(!updatedShowModal);
    }
    const changeGift = index => {
        product.selectedGift = product.gifts[index];
        update(product.selectedGift);
    };

    return (
        <div>
            <Tooltip placement="top" title="Click để chọn quà khác">
                <div style={{position: 'absolute', top: '-5px', right: '135px', 'width': '85px', height: '24px'}} onClick = {() => {changeModal()}}></div>
            </Tooltip>
            <Modal
                title="Chọn quà"
                visible={updatedShowModal}
                onCancel={() => changeModal()}
                footer={null}
                maskClosable={false}
            >
            <Select
                defaultValue={(product.selectedGift ? product.selectedGift.name : null) || 'Chọn sticker'}
                onChange={changeGift}
                style={{ width: 450 }}>
                {product.gifts.map((gift, index) => (
                    <Option key={index} value={index}>
                        {gift.name}
                    </Option>
                ))}
                </Select>
            </Modal>
        </div>
    );
}

function Gift({product}) {
    const [updatedProduct, setUpdatedProduct] = useState(product);
    let giftName = product.selectedGift.name;
    const [updatedGiftName, setUpdatedGiftName] = useState(giftName);
    const updateGiftName = () => {
        let newGiftName = prompt("Sửa tên quà tặng", giftName);
        if(newGiftName){
            product.selectedGift.name = newGiftName;
            setUpdatedProduct(product);
            setUpdatedGiftName(updatedProduct.selectedGift.name);
        }
    };
    let gift = product.selectedGift;
    const [updatedGift, setUpdatedGift] = useState(gift);
    const updateGift = () => {
        setUpdatedGift(updatedProduct.selectedGift);
        setUpdatedGiftName(updatedProduct.selectedGift.name);
    };
    return (
        <StyledGift>
            {product.selectedGift && product.gifts.length > 1 ? <GiftSelector product={updatedProduct} update={updateGift}/> : null }
            {product.selectedGift.image ? <img alt="" src={updatedGift.image} style={{ maxWidth: '180px', maxHeight: '100px' }}/> : null}
            <Tooltip placement="bottom" title="Click để sửa tên quà">
            {
                updatedProduct.selectedGift.image 
                ? <StyledGiftName onClick={ () => updateGiftName() }>{updatedGiftName}</StyledGiftName> 
                : <div style={{width: '220px', marginTop: '30px', marginLeft: '60px', fontSize: '10pt'}} onClick={ () => updateGiftName() }>{updatedGiftName}</div>
            }
            </Tooltip>
        </StyledGift>
    );
}

function Content({product, sticker}) {
    if(sticker === "Discount only"){
        return (
            <StyledBody className="mx-auto mt-2">
                <div style={{position: "absolute", top: 40, width: 340}}>
                    <Name product={product} bigFont/>
                    {product.price ? <DiscountAmount product={product} /> : null}
                    {product.price ? <Price price={product.price}/> : null}
                    {product.finalPrice ? <FinalPrice price={product.finalPrice}/> : null}
                    <StyledPromoRange>{product.promoRange}</StyledPromoRange>
                </div>
            </StyledBody>
        );
    }
    else if(sticker === "Gift only"){
        return (
            <StyledBody className="mx-auto mt-2">
                <div style={{position: "absolute", top: 40, width: 340}}>
                    <Name product={product} height='55px'/>
                    {product.finalPrice ? <FinalPrice price={product.finalPrice}/> : null}
                </div>
                <StyledPromoRangeSmall>{product.promoRange}</StyledPromoRangeSmall>
                {product.selectedGift ? <Gift product={product}/> : null}
            </StyledBody>
        );
    }
    else if(sticker === "Discount & gift"){
        return (
            <StyledBody className="mx-auto mt-2">
                <div style={{position: "absolute", top: 40, width: 340}}>
                    <Name product={product} height='40px'/>
                    <div style={{padding: '0 30px'}}>
                        { product.price ? <PriceSmall price={product.price} /> : null}
                        <DiscountPrice product={product}/>
                    </div>
                    {product.finalPrice ? <FinalPrice price={product.finalPrice} /> : null}
                </div>
                <StyledPromoRangeSmall>{product.promoRange}</StyledPromoRangeSmall>
                {product.selectedGift ? <Gift product={product}/> : null}
            </StyledBody>
        );
    }
    else return null;
}

export default Content;
