import {decodeHTML, splitArrayToChunks} from 'utils';
import {listing_api} from './apis';

const breaklines = /\n|<br>|<br\/>|<span>|<\/span>|- |\+ |<p>|<\/p>/g;

const filterProduct = product => {
    const result = {};
    result.name = product.displayName ? product.displayName : product.name;
    result.sku = product.sku;
    result.warranty = product.warranty.months;
    result.price = getOldPrice(product);
    result.finalPrice = getFinalPrice(product);
    result.key = product.sku;
    result.gifts = [];
    const parseDescription = rawDescription => {
        if (!rawDescription) return [];
        rawDescription = decodeHTML(rawDescription);
        rawDescription = rawDescription.replace(/- /g, '\n- ');
        const brDescriptions = rawDescription.split(breaklines);
        const trimmedDescriptions = brDescriptions.map(line => {
            return line.trim();
        });
        const filteredEmptyLines = trimmedDescriptions.filter(line => line.length > 2);
        return filteredEmptyLines;
    };
    result.descriptions = parseDescription(product.seoInfo.shortDescription);
    return result;
};

export const fetchProductsByChunks = async (skuList) => {
    const url = `${listing_api}${skuList.join(',')}`
    try {
        const response = await fetch(url);
        const data = await response.json();
        const products = data.result.products.map(product => filterProduct(product));
        return products;
    } catch (error) {
        return [];
    }
};

export const fetchProducts = async (skuList) => {
    const skuChunks = splitArrayToChunks(skuList, 10);
    const fetching = [];
    for (const skuChunk of skuChunks) {
        fetching.push(fetchProductsByChunks(skuChunk));
    }
    const fetchedData = await Promise.all(fetching);
    return [].concat(...fetchedData);
};

function getOldPrice(product) {
    let old_price = null;
    if (product.price) {
      if (product.price.supplierSalePrice)
        old_price = product.price.supplierSalePrice;
      else if (product.price.sellPrice) old_price = product.price.sellPrice;
    }
    return old_price;
}

function getFinalPrice(product) {
    let final_price = null;
    if (product.price) final_price = product.price.sellPrice;
    let promotionPrices = getShowroomChannelValue(product.promotionPrices);
    if (promotionPrices) {
        final_price = promotionPrices.bestPrice ? promotionPrices.bestPrice : promotionPrices.finalPrice;
    }
    return final_price;
}

function getShowroomChannelValue(value) {
    let result = null;
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].channel === "all") result = value[i];
        if (value[i].channel === "pv_showroom") {
          return value[i];
        }
      }
    }
    return result;
}