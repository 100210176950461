import {decodeHTML, splitArrayToChunks} from 'utils';
import {listing_api} from './apis';

const breaklines = /\n|<br>|<br\/>|<span>|<\/span>|- |\+ |<p>|<\/p>/g;

const filterProduct = product => {
    const result = {};
    result.name = product.name;
    result.sku = product.sku;
    result.warranty = product.warranty.months;
    result.price = getOldPrice(product);
    result.finalPrice = getFinalPrice(product);
    result.key = product.sku;
    getGifts(product).then(gifts => {
        result.gifts = gifts;
        result.selectedGift = gifts && gifts.length > 0 ? gifts[0] : null
      });
    const parseDescription = rawDescription => {
        if (!rawDescription) return [];
        rawDescription = decodeHTML(rawDescription);
        rawDescription = rawDescription.replace(/- /g, '\n- ');
        const brDescriptions = rawDescription.split(breaklines);
        const trimmedDescriptions = brDescriptions.map(line => {
            let trimmed = line.trim();
            if (trimmed[0] !== '-') trimmed = '- ' + trimmed;
            return trimmed;
        });
        const filteredEmptyLines = trimmedDescriptions.filter(line => line.length > 2);
        return filteredEmptyLines;
    };
    result.descriptions = parseDescription(product.seoInfo.shortDescription);
    result.promoRange = '';
    return result;
};

const filterGift = gift => {
    const result = {};
    result.name = gift.name;
    result.sku = gift.sku;
    result.image = getListingImage(gift.images, 110);
    return result;
};

export const fetchProductsByChunks = async skuList => {
    const url = `${listing_api}${skuList.join(',')}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        const products = data.result.products.map(product => filterProduct(product));
        return products;
    } catch (error) {
        return [];
    }
};

export const fetchProducts = async skuList => {
    const skuChunks = splitArrayToChunks(skuList, 10);
    const fetching = [];
    for (const skuChunk of skuChunks) {
        fetching.push(fetchProductsByChunks(skuChunk));
    }
    const fetchedData = await Promise.all(fetching);
    return [].concat(...fetchedData);
};

function getOldPrice(product) {
  let old_price = null;
  if (product.price) {
    if (product.price.supplierSalePrice)
      old_price = product.price.supplierSalePrice;
    else if (product.price.sellPrice) old_price = product.price.sellPrice;
  }
  return old_price;
}

function getFinalPrice(product) {
  let final_price = null;
  if (product.price) final_price = product.price.sellPrice;
  let promotionPrices = getShowroomChannelValue(product.promotionPrices);
  if (promotionPrices) {
      final_price = promotionPrices.bestPrice ? promotionPrices.bestPrice : promotionPrices.finalPrice;
  }
  return final_price;
}

function getShowroomChannelValue(value) {
    let result = null;
    if (value && value.length > 0) {
      for (let i = 0; i < value.length; i++) {
        if (value[i].channel === "all") result = value[i];
        if (value[i].channel === "pv_showroom") {
          return value[i];
        }
      }
    }
    return result;
}

function getGiftSkus(product){
    let giftSkus = [];
    if(product.promotions && product.promotions.length > 0){
        for(let i in product.promotions){
            if(product.promotions[i].definitions && product.promotions[i].definitions.length > 0){
                for(let j in product.promotions[i].definitions){
                    let definition = product.promotions[i].definitions[j];
                    if(definition.benefit){
                        if(definition.benefit.items && definition.benefit.items.length > 0){
                            for(let k in definition.benefit.items){
                                giftSkus.push(definition.benefit.items[k].sku);
                            }
                        }
                    }
                }
            }
        }
    }
    return giftSkus;
}

function getListingImage(images, height) {
    let image = null;
    if (images && images.length > 0) {
      image = images[0].url;
      if (images[0].path) {
        if (images[0].path.slice(0, 4) !== "http") {
          if (images[0].path.charAt(0) === "/") {
            image = "https://img.vnshop.vn/height/" + height + images[0].path;
          } else {
            image =
              "https://img.vnshop.vn/height/" + height + "/" + images[0].path;
          }
        }
      }
    }
    return image;
  }

async function getGifts(product){
    let gifts = [];
    let skus = getGiftSkus(product);
    if(skus.length > 0){
        let url = `${listing_api}${skus.join(',')}`;
        try {
            let response = await fetch(url);
            let data = await response.json();
            gifts.push(data.result.products.map(gift => filterGift(gift)));
            return [].concat(...gifts);
        } catch (error) {
            return [];
        }
    }
}