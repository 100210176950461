export const Stickers = [
  {
    name: "Discount only",
    url: require('asset/sticker-discount.jpg')
  },
  {
    name: "Gift only",
    url: require('asset/sticker-gift.jpg')
  },
  {
    name: "Discount & gift",
    url: require('asset/sticker-gift.jpg')
  }
];
