import React from 'react';
import styled from 'styled-components';
import Content from './Content';

function ProductSticker({ sticker, product, updateProduct}) {
  const StyledForm = styled.div`
    height: 9cm;
    width: 9cm;
    position: relative;
    font-family: Montserrat-SemiBold;
    background: url(${sticker.url});
    background-repeat: no-repeat;
    background-size: contain;
  `;

  const Form = (
    <StyledForm className="mx-auto">
      <Content product={product} updateProduct={updateProduct} sticker={sticker.name}/>
    </StyledForm>
  );

  return Form;
}

export default ProductSticker;
