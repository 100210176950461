export function EslSize(
  displayName,
  height,
  width,
  itemsPerPage = 1,
  rotation = false
) {
  return {
    displayName,
    height: height,
    width: width,
    itemsPerPage: itemsPerPage,
    rotation: rotation,
  };
}

export const EslSizes = [
  EslSize("Nhỏ", 125, 240, 21),
  EslSize("Lớn", 235, 310, 9, true),
  EslSize("9x10", 340, 378, 6),
];
