import React, { Fragment } from "react";
import { connect } from "react-redux";
import ProductEsl from "components/ProductEsl";
import { splitArrayToChunks } from "utils";

function Page({ size, products, installmentInfo }) {
  return (
    <div className="page-to-print d-flex flex-wrap">
      {products.map((product) => (
        <div key={product.key} style={{ padding: 0, margin: "2mm" }}>
          <ProductEsl
            {...{ product, size, rotate: size.rotation, installmentInfo }}
          />
        </div>
      ))}
    </div>
  );
}

class PrintProductEsl extends React.Component {
  render() {
    const { products, size, history, installmentInfo } = this.props;
    if (products.length === 0) history.push("/");
    const itemsPerPage = size ? size.itemsPerPage : null;
    const productChunks = splitArrayToChunks(products, itemsPerPage);
    return (
      <Fragment>
        {productChunks.map((productChunk, index) => (
          <Page
            key={index}
            products={productChunk}
            size={size}
            installmentInfo={installmentInfo}
          />
        ))}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.print.products,
  size: state.print.size,
  installmentInfo: state.print.installmentInfo,
});

export default connect(mapStateToProps)(PrintProductEsl);
