import React from "react";
import { connect } from "react-redux";
import * as _ from "lodash";
import { Typography, Button, Card, message, Tooltip, Input, Row } from "antd";
import { withRouter } from "react-router-dom";
import ProductEsl from "components/ProductEsl";
import {
  PRINT_INSTALLMENTINFO_MONTH_UPDATE,
  PRINT_INTEREST_RATE_UPDATE,
} from "../../actions/types";

const { Text } = Typography;

const MouseOverWrapper = ({ product, reloadDescriptions, children }) => {
  if (!product) return children;
  return (
    <Tooltip
      placement="right"
      title="Tải lại thông tin cho sản phẩm này"
      onClick={reloadDescriptions}
    >
      {children}
    </Tooltip>
  );
};

function PreviewTitleNoRouter({
  product,
  size,
  updateProduct,
  history,
  fetchedProducts,

  interestRate,
  month,
  updateInterestRate,
  updateMonth,
}) {
  const reloadDescriptions = () => {
    const rawProduct = fetchedProducts.find((e) => e.sku === product.sku);
    updateProduct(rawProduct);
  };

  return (
    <div className="d-flex justify-content-between" style={{ width: "100%" }}>
      <div
        className="py-1 d-flex px-1"
        style={{ whiteSpace: "normal", flexGrow: 1 }}
      >
        <div className="d-flex justify-space-between">
          <Text strong className="pr-4">
            Preview
          </Text>
          <Row className="d-flex" style={{ gap: "0.5rem" }}>
            <div>
              <label className="d-block">Lãi suất (%): </label>
              <Input
                type="number"
                style={{ width: 150 }}
                min={0}
                max={100}
                step={0.5}
                value={interestRate}
                onChange={(e) =>
                  updateInterestRate(
                    e.target.value < 0
                      ? Math.abs(e.target.value)
                      : e.target.value
                  )
                }
              />
            </div>
            <div>
              <label className="d-block">Thời hạn (tháng): </label>
              <Input
                type="number"
                style={{ width: 150 }}
                value={month}
                min={1}
                step={1}
                onChange={(e) =>
                  updateMonth(
                    e.target.value < 0
                      ? Math.abs(e.target.value)
                      : e.target.value
                  )
                }
              />
            </div>
          </Row>
        </div>
        <MouseOverWrapper {...{ product, reloadDescriptions }}>
          <Text className="pl-4 custom-hover-blue custom-hover-strong">
            {product ? product.name : null}
          </Text>
        </MouseOverWrapper>
      </div>
      <Button
        type="primary"
        style={{ minWidth: "min-content" }}
        onClick={() => {
          if (!size) {
            message.error("Chọn kích thước trước khi in!");
          } else {
            history.push("/print-esl");
          }
        }}
      >
        In ESL
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  fetchedProducts: state.products,
  interestRate: state.print.installmentInfo.interestRate,
  month: state.print.installmentInfo.month,
});
const mapDispatchToProps = (dispatch) => ({
  updateInterestRate: (value) =>
    dispatch({ type: PRINT_INTEREST_RATE_UPDATE, data: value }),
  updateMonth: (value) =>
    dispatch({ type: PRINT_INSTALLMENTINFO_MONTH_UPDATE, data: value }),
});

const PreviewTitle = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PreviewTitleNoRouter));

function PreviewProductEsl({ preview, updateProduct }) {
  const { size, product, installmentInfo } = preview;
  return (
    <Card
      title={<PreviewTitle {...{ product, updateProduct, size }} />}
      type="inner"
      size="small"
      hoverable
      bodyStyle={{ height: 800, overflow: "scroll" }}
    >
      {_.isEmpty(product) || _.isEmpty(size) ? null : (
        <ProductEsl {...{ size, product, updateProduct, installmentInfo }} />
      )}
    </Card>
  );
}

export default PreviewProductEsl;
