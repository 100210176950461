import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ProductSticker from 'components/ProductSticker';
import { splitArrayToChunks } from 'utils';

function Page({ products, sticker }) {
  return (
    <div className="page-to-print d-flex flex-wrap">
      {products.map(product => (
        <div key={product.key} style={{ padding: 0, marginLeft: '1cm'}}>
          <ProductSticker {...{ product, sticker }} />
        </div>
      ))}
    </div>
  );
}

class PrintProductSticker extends React.Component {
  render() {
    const { products, sticker, history } = this.props;
    if (products.length === 0) history.push('/');
    const itemsPerPage = 6;
    const productChunks = splitArrayToChunks(products, itemsPerPage);
    return (
      <Fragment>
        {productChunks.map((productChunk, index) => (
          <Page key={index} products={productChunk} sticker={sticker} />
        ))}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  products: state.print.products,
  sticker: state.print.sticker
});

export default connect(mapStateToProps)(PrintProductSticker);
