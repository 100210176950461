import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { Typography, Button, Card, message } from 'antd';
import { withRouter } from 'react-router-dom';
import ProductSticker from 'components/ProductSticker';

const { Text } = Typography;

function PreviewTitleNoRouter({ sticker, product, updateProduct, products, updateProducts, history }) {
  const updatePromoRange = () => {
      let newPromoRange = prompt("Nhập khoảng thời gian", product.promoRange);
      if(newPromoRange) {
          product.promoRange = newPromoRange;
          updateProduct(product);
          const newProducts = products.map(item => { return { ...item, promoRange: newPromoRange }});
          updateProducts(newProducts);
      }
  };

  return (
    <div className="d-flex justify-content-between" style={{ width: '100%' }}>
      <div className="py-1">
          <Text strong>Preview</Text>
          {product && sticker ? <Button className="ml-5" onClick={updatePromoRange}>Chọn khoảng thời gian KM</Button> : null }
      </div>
      <Button
        type="primary"
        style={{ width: 120 }}
        onClick={() => {
          if (!sticker) {
            message.error('Chọn sticker trước khi in!');
          } else {
            history.push('/print-sticker');
          }
        }}>
        In sticker
      </Button>
    </div>
  );
}

const mapStateToProps = state => ({
  fetchedProducts: state.products
});

const PreviewTitle = connect(mapStateToProps)(withRouter(PreviewTitleNoRouter));

function PreviewProductSticker({ preview, updateProduct, products, updateProducts }) {
  const { sticker, product } = preview;
  return (
    <Card
      title={<PreviewTitle {...{ sticker, product, updateProduct, products, updateProducts }} />}
      type="inner"
      size="small"
      hoverable
      bodyStyle={{ height: 800, overflow: 'scroll' }}>
      {_.isEmpty(product) || _.isEmpty(sticker) ? null : <ProductSticker {...{ sticker, product, updateProduct }} />}
    </Card>
  );
}

export default PreviewProductSticker;
