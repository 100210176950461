import React from 'react';
import './App.css';
import DefaultLayout from 'views/DefaultLayout';
import StickerLayout from 'views/StickerLayout';
import EslLayout from 'views/EslLayout';
import PrintProductTag from 'views/Print';
import PrintProductSticker from 'views/PrintSticker';
import PrintProductEsl from 'views/PrintEsl';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/sticker" component={StickerLayout} />
        <Route path="/print-sticker" component={PrintProductSticker} />
        <Route path="/esl" component={EslLayout} />
        <Route path="/print-esl" component={PrintProductEsl} />
        <Route path="/print" component={PrintProductTag} />
        <Route path="/product-tag" component={DefaultLayout} />
        <Route path="/" component={DefaultLayout} />
        <Route path="/bang-ten" component={DefaultLayout} />
      </Switch>
    </Router>
  );
}

export default App;
