import React from 'react';
import { Select } from 'antd';

const { Option } = Select;

function PreviewProductSelector({ selectedProducts, previewProduct, updatePreviewProduct }) {
  const handleChange = key => {
    const index = selectedProducts.findIndex(e => e.key === key);
    updatePreviewProduct(selectedProducts[index]);
  };

  if (selectedProducts.length && !previewProduct) {
    const { key, sku, name } = selectedProducts[0];
    handleChange(key);
    return <Select value={sku + ' - ' + name} style={{ width: 500 }} />;
  }

  return (
    <Select defaultValue="Chọn sản phẩm" style={{ width: 470 }} onChange={handleChange}>
      {selectedProducts.map(product => (
        <Option key={product.key} value={product.key}>
          {product.sku} - {product.name}
        </Option>
      ))}
    </Select>
  );
}

function PreviewStickerSelector({ updatePreviewSticker, stickers, selectedSticker }) {
  const handleChange = index => {
    updatePreviewSticker(stickers[index]);
  };
  return (
    <Select
      defaultValue={(selectedSticker ? selectedSticker.name : null) || 'Chọn sticker'}
      style={{ width: 300 }}
      onChange={handleChange}>
      {stickers.map((sticker, index) => (
        <Option key={index} value={index}>
          {sticker.name}
        </Option>
      ))}
    </Select>
  );
}

export { PreviewProductSelector, PreviewStickerSelector };
