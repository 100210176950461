import React, { useState, Fragment, useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import { connect } from 'react-redux';
import { PreviewProductSelector, PreviewStickerSelector } from './Selectors';
import PreviewProductSticker from './PreviewProductSticker';
import { Stickers } from 'constant/Sticker';
import { PRINT_PRODUCTS_UPDATE, PRINT_STICKER_UPDATE } from 'actions/types';

const { Text } = Typography;

function Preview({ sticker, updateSticker, products, updateProducts }) {
  const [previewProduct, setPreviewProduct] = useState();
  const [reset, forceReset] = useState(1);
  useEffect(() => {
    if (previewProduct && products.findIndex(e => e.sku === previewProduct.sku) === -1) {
      setPreviewProduct(null);
      forceReset(-reset);
    }
  }, [products]);

  const updatePreviewProduct = product => {
    let index = products.findIndex(element => element.name === product.name);
    let updated = products.slice();
    updated[index] = product;
    setPreviewProduct(product);
    updateProducts(updated);
  };

  return (
    <Fragment>
      <Col className="row justify-content-between">
        <Col className="row ml-1">
          <Text strong className="pt-1 pr-2">
            Sản phẩm
          </Text>
          <PreviewProductSelector
            key={reset}
            selectedProducts={products}
            previewProduct={previewProduct}
            updatePreviewProduct={setPreviewProduct}
          />
        </Col>
        <Col>
          <Text strong className="pt-1 pr-2">
            Sticker
          </Text>
          <PreviewStickerSelector stickers={Stickers} updatePreviewSticker={updateSticker} selectedSticker={sticker}/>
        </Col>

        <Row className="mt-2" style={{ width: '105%' }}>
        <PreviewProductSticker preview={{ sticker: sticker, product: previewProduct }} updateProduct={updatePreviewProduct} products={products} updateProducts={updateProducts} />
        </Row>
      </Col>
    </Fragment>
  );
}

const mapStateToProps = state => ({
  sticker: state.print.sticker,
  products: state.print.products
});

const mapDispatchToProps = dispatch => ({
  updateSticker: sticker => dispatch({ type: PRINT_STICKER_UPDATE, data: sticker }),
  updateProducts: products => dispatch({ type: PRINT_PRODUCTS_UPDATE, data: products })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Preview);
