import React from "react";
import styled from "styled-components";
import HeaderSmall from "./HeaderSmall";
import HeaderLarge from "./HeaderLarge";
import HeaderOrigin from "./HeaderOrigin";
import ContentSmall from "./ContentSmall";
import ContentLarge from "./ContentLarge";
import ContentOrigin from "./ContentOrigin";
import FooterSmall from "./FooterSmall";
import FooterLarge from "./FooterLarge";
import FooterOrigin from "./FooterOrigin";

function ProductEsl({
  size,
  product,
  updateProduct,
  installmentInfo,
  rotate = false,
}) {
  const StyledForm = styled.div`
    height: ${size.height}px;
    width: ${size.width}px;
    position: relative;
    font-family: Montserrat-Regular;
    background: #d3d3d3;
  `;

  const StyledRotatedForm = styled.div`
    height: ${size.width}px;
    width: ${size.height}px;
    padding: 0;
  `;

  const Form = (
    <StyledForm className="mx-auto">
      {size.displayName === "Nhỏ" ? (
        <>
          <HeaderSmall product={product} updateProduct={updateProduct} />
          <ContentSmall product={product} updateProduct={updateProduct} />
          <FooterSmall product={product} installmentInfo={installmentInfo} />
        </>
      ) : size.displayName === "Lớn" ? (
        <>
          <HeaderLarge product={product} updateProduct={updateProduct} />
          <ContentLarge product={product} updateProduct={updateProduct} />
          <FooterLarge product={product} installmentInfo={installmentInfo} />
        </>
      ) : (
        <>
          <HeaderOrigin product={product} updateProduct={updateProduct} />
          <ContentOrigin product={product} updateProduct={updateProduct} />
          <FooterOrigin product={product} installmentInfo={installmentInfo} />
        </>
      )}
    </StyledForm>
  );

  if (rotate) {
    return (
      <StyledRotatedForm>
        <div style={{ transform: "rotate(90deg)" }}>{Form}</div>
      </StyledRotatedForm>
    );
  }

  return Form;
}

export default ProductEsl;
