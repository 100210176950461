import {tekshop_sku} from './apis';
import {decodeHTML, splitArrayToChunks} from 'utils';

const breaklines = /\n|<br>|<br\/>|<span>|<\/span>|- |\+ |<p>|<\/p>/g;

const filterProduct = product => {
    const result = {};
    result.name = product.name;
    result.sku = product.sku;
    result.warranty = product.warranty.months;
    result.price = Number(product.price.supplierSalePrice);
    result.finalPrice = product.price.sellPrice;
    result.key = product.sku;
    result.gifts = [];
    const parseDescription = rawDescription => {
        if (!rawDescription) return [];
        rawDescription = decodeHTML(rawDescription);
        rawDescription = rawDescription.replace(/- /g, '\n- ');
        const brDescriptions = rawDescription.split(breaklines);
        const trimmedDescriptions = brDescriptions.map(line => {
            let trimmed = line.trim();
            if (trimmed[0] !== '-') trimmed = '- ' + trimmed;
            return trimmed;
        });
        const filteredEmptyLines = trimmedDescriptions.filter(line => line.length > 2);
        return filteredEmptyLines;
    };
    result.descriptions = parseDescription(product.seoInfo.shortDescription);
    return result;
};

export const fetchProductsByChunks = async skuList => {
    const url = `${tekshop_sku}${skuList.join(',')}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        const products = data.result.products.map(product => filterProduct(product));
        return products;
    } catch (error) {
        return [];
    }
};

export const fetchProducts = async skuList => {
    const skuChunks = splitArrayToChunks(skuList, 10);
    const fetching = [];
    for (const skuChunk of skuChunks) {
        fetching.push(fetchProductsByChunks(skuChunk));
    }
    const fetchedData = await Promise.all(fetching);
    return [].concat(...fetchedData);
};

