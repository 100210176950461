import {
  PRINT_PRODUCTS_UPDATE,
  PRINT_SIZE_UPDATE,
  PRINT_STICKER_UPDATE,
} from "actions/types";
import {
  PRINT_INSTALLMENTINFO_MONTH_UPDATE,
  PRINT_INTEREST_RATE_UPDATE,
} from "../actions/types";

const initialState = {
  size: null,
  products: [],
  sticker: null,
  installmentInfo: {
    month: 6,
    interestRate: 0,
  },
};

const printReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRINT_PRODUCTS_UPDATE:
      return { ...state, products: action.data };
    case PRINT_SIZE_UPDATE:
      return { ...state, size: action.data };
    case PRINT_STICKER_UPDATE:
      return { ...state, sticker: action.data };
    case PRINT_INTEREST_RATE_UPDATE:
      return {
        ...state,
        installmentInfo: {
          ...state.installmentInfo,
          interestRate: action.data,
        },
      };
    case PRINT_INSTALLMENTINFO_MONTH_UPDATE:
      return {
        ...state,
        installmentInfo: { ...state.installmentInfo, month: action.data },
      };
    default:
      return state;
  }
};

export default printReducer;
